<template>
  <section class="section">
    <div class="columns is-centered">
      <div class="column is-10-desktop is-8-widescreen">
        <div class="box box-info" style="color:black">
          <div class="box-header content with-border">
            <div class="text-center">
              <h1>{{ this.$route.name.toUpperCase() }}</h1>
            </div>
          </div>
          <div class="box-body">
            <!-- login form -->
            <form class="loginForm" @submit.prevent="checkCreds">
              <b-field label="User / Email">
                <b-input v-model="email" size="is-medium" placeholder="Your email or username" />
              </b-field>
              <!-- <div class="form-group col-sm-12">
                    <label for="username" class="col-sm-2 control-label">Username / Email*</label>
                    <div class="input-group">
                      <span class="input-group-addon"><i class="fa fa-users"></i></span>
                      <input class="form-control" name="email" placeholder="Username or Email" type="text" v-model="email" autocomplete="off">
                    </div>
                  </div> -->
              <div class="has-text-centered">
                <button type="submit" :class="'btn button is-link btn-lg ' + loading">
                  Submit
                </button>
              </div>
            </form>
            <p>&nbsp;</p>
            <!-- errors -->
            <div v-if="response" class="notification is-info">
              <p>{{ response }}</p>
            </div>
            <div class="text-center col-md-12">
              <router-link to="/login">
                Sign in
              </router-link> | <router-link to="/register">
                Create account
              </router-link> | <a href="https://play.edshed.com">Web Game</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { request } from '@/edshed-common/api'

export default {
  name: 'ForgotPassword',
  data (router) {
    return {
      section: 'Forgot',
      loading: '',
      email: '',
      response: ''
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.$store.state.user != null) {
        this.$router.push({ path: 'add-school' })
      }
    })
  },
  methods: {
    checkCreds (e) {
      const { email } = this

      if (email === null || email === '') { // ae3d0abdbc5ff095ede393d606b81d7d57be443a

      } else {
        this.toggleLoading()
        this.resetResponse()
        this.$store.commit('TOGGLE_LOADING')

        /* Making API call to authenticate a user */
        request('POST', 'users/forgot', { email })
          .then((response) => {
            this.toggleLoading()
            const data = response.data
            if (data.error) {
              this.response = 'User not found'
            } else {
              this.response = 'Request sent'
            }
          })
          .catch((error) => {
            this.$store.commit('TOGGLE_LOADING')
            console.log(error)

            this.response = 'User not found'
            this.toggleLoading()
          })
      }
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    },
    resetResponse () {
      this.response = ''
    }
  }
}
</script>
<style scoped>
  body {
    background-image: url('/public/img/skyBackground.jpg');
    background-size: cover;
    background-repeat:no-repeat;
    background-color: #b4e3f7;
    background-color: #f8f8f8;
  }
</style>
